<style>
</style>

<template>
  <div>
    <button v-on:click="checkout" class="button button--primary">Subscribe</button>
  </div>
</template>

<script>
  export default {
    methods: {
      checkout: function (event) {
        let stripe = window.Stripe(this.token);

        stripe.redirectToCheckout({
          sessionId: this.session
        });
      }
    },
    props: {
      session: {
        type: String,
        required: true
      },
      token: {
        type: String,
        required: true
      }
    }
  };
</script>
