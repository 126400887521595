/* eslint no-console:0 */

import 'core-js/stable';
import 'regenerator-runtime/runtime';

import TurbolinksAdapter from 'vue-turbolinks';
import Vue from 'vue/dist/vue.esm';
import Subscriptions from '../components/subscriptions.vue';

Vue.use(TurbolinksAdapter)

document.addEventListener('turbolinks:load', () => {
  let element = document.getElementById('subscriptions');
  if (element != null) {
    const app = new Vue({
      el: element,
      components: { Subscriptions }
    })
  }
})
